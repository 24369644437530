import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import kebabCase from "lodash/kebabCase"

const CategoriesPage = ({
  data: {
    categoryGroup: { group },
  },
}) => {
  return (
    <Layout>
      <ul>
        {group.map(category => (
          <li key={category.categoryName}>
            <Link to={`/browse/${kebabCase(category.categoryName)}`}>
              {category.categoryName} | {category.totalCount}
            </Link>
          </li>
        ))}
      </ul>
    </Layout>
  )
}

export default CategoriesPage
export const pageQuery = graphql`
  query {
    categoryGroup: allAirtable(
      limit: 2000
      filter: {
        table: { eq: "Products" }
        data: { Diameter_mm_calc: { gt: 0 }, Published: { eq: true } }
      }
    ) {
      group(field: data___Category) {
        categoryName: fieldValue
        totalCount
      }
    }
  }
`
